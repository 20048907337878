import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
	groupMenuToggle,
	groupMenuClose,
	vacanciesFiltersOpen,
	vacanciesFiltersClose,
	toggleAdminMenu,
	closeAdminMenu,
} from 'action/ui';

import ArrowGoBack from './arrowGoBack';
import GroupMenu from './groupMenu';
import MobileVacanciesFilters from './vacanciesFilters';
import MobileAdminMenu from './mobileAdminAside';
import { getChatbotForUserRest } from 'shared/api/messenger';

import './style.scss';

const News = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Новости</span>
		</ArrowGoBack>
	);
};

export default connect(
	state => ({
		groupMenuView: state.ui.groupMenuView,
	}),
	dispatch => ({
		groupMenuToggle: () => {
			dispatch(groupMenuToggle());
		},
		groupMenuClose: () => {
			dispatch(groupMenuClose());
		},
	}),
	null,
	{ pure: false }
)(News);

export const HomePage = () => {
	return (
		<div className="mobile-header-title-block">
			<span className="mobile-header-title-block__text">Макси Портал</span>
		</div>
	);
};

export const HomePageNews = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Новость</span>
		</ArrowGoBack>
	);
};

const NewsWithArrowFunc = props => {
	const toggleGroupMenuHandler = e => {
		e.preventDefault();
		props.groupMenuToggle();
		if (document.getElementById('wrapp').classList.contains('no-scroll')) {
			document.getElementById('wrapp').classList.remove('no-scroll');
		} else {
			document.getElementById('wrapp').classList.add('no-scroll');
		}
	};

	return (
		<ArrowGoBack {...props}>
			<span>Новости</span>
			<svg
				role="img"
				className={props.groupMenuView ? 'icon icon_group opened' : 'icon icon_group'}
				onClick={toggleGroupMenuHandler}
			>
				<use
					xlinkHref={process.env.REACT_APP_PUBLIC_URL + '/img/sprite.svg#bold-mini-arrow'}
				></use>
			</svg>
			<GroupMenu groupMenuView={props.groupMenuView} />
		</ArrowGoBack>
	);
};

export const NewsWithArrow = connect(
	state => ({
		groupMenuView: state.ui.groupMenuView,
	}),
	dispatch => ({
		groupMenuToggle: () => {
			dispatch(groupMenuToggle());
		},
		groupMenuClose: () => {
			dispatch(groupMenuClose());
		},
	}),
	null,
	{ pure: false }
)(NewsWithArrowFunc);

const VacanciesMain = props => {
	const toggleVacFiltersHandler = e => {
		e.preventDefault();
		props.vacanciesFiltersOpen();
	};

	return (
		<ArrowGoBack {...props}>
			<span>Вакансии</span>

			{props.match.isExact && (
				<>
					{!props.vacFiltersView && (
						<svg role="img" className="icon" onClick={toggleVacFiltersHandler}>
							<use
								xlinkHref={
									process.env.REACT_APP_PUBLIC_URL + `/img/sprite.svg#filter`
								}
							></use>
						</svg>
					)}
					{props.vacFiltersView && <MobileVacanciesFilters />}
				</>
			)}
		</ArrowGoBack>
	);
};

export const Vacancies = connect(
	state => ({
		vacFiltersView: state.ui.vacFiltersView,
	}),
	dispatch => ({
		vacanciesFiltersOpen: () => {
			dispatch(vacanciesFiltersOpen());
		},
		vacanciesFiltersClose: () => {
			dispatch(vacanciesFiltersClose());
		},
	}),
	null,
	{ pure: false }
)(VacanciesMain);

export const Contacts = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Справочник</span>
		</ArrowGoBack>
	);
};

export const User = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Профиль</span>
		</ArrowGoBack>
	);
};

export const Group = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Группа</span>
		</ArrowGoBack>
	);
};

export const Thanks = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Все спасибо</span>
		</ArrowGoBack>
	);
};

export const Club = props => {
	return (
		<ArrowGoBack {...props}>
			<span>МаксиКлуб</span>
		</ArrowGoBack>
	);
};

export const Appointments = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Назначения</span>
		</ArrowGoBack>
	);
};

export const History = props => {
	return (
		<ArrowGoBack {...props}>
			<span>История</span>
		</ArrowGoBack>
	);
};

export const Documents = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Нормативные документы</span>
		</ArrowGoBack>
	);
};

export const Professional = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Макси Professional</span>
		</ArrowGoBack>
	);
};

export const Walkfame = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Доска почета</span>
		</ArrowGoBack>
	);
};

export const Help = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Помощь</span>
		</ArrowGoBack>
	);
};

export const Activities = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Мероприятия</span>
		</ArrowGoBack>
	);
};

export const Activity = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Мероприятие</span>
		</ArrowGoBack>
	);
};

export const Glossary = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Глоссарий</span>
		</ArrowGoBack>
	);
};

export const Guide = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Гид по компании</span>
		</ArrowGoBack>
	);
};

export const Faq = props => {
	return (
		<ArrowGoBack {...props}>
			<span>FAQ</span>
		</ArrowGoBack>
	);
};

export const Search = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Результаты поиска</span>
		</ArrowGoBack>
	);
};

export const Services = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Сервисы</span>
		</ArrowGoBack>
	);
};

export const Structure = props => {
	return (
		<ArrowGoBack {...props}>
			<span> Структура компании</span>
		</ArrowGoBack>
	);
};

export const Strategy = props => {
	return (
		<ArrowGoBack {...props}>
			<span> Стратегия компании</span>
		</ArrowGoBack>
	);
};

export const Birthday = props => {
	return (
		<ArrowGoBack {...props}>
			<span> Именинники</span>
		</ArrowGoBack>
	);
};

export const Mirapolis = props => {
	return (
		<ArrowGoBack {...props}>
			<span> Мираполис</span>
		</ArrowGoBack>
	);
};

const AdminMain = props => {
	const toggleAdminMenuHandler = e => {
		e.preventDefault();
		props.toggleAdminMenu();
	};

	return (
		<ArrowGoBack {...props}>
			<span>Панель администратора</span>

			<>
				<svg
					role="img"
					className={props.adminMenuView ? 'icon opened' : 'icon'}
					onClick={toggleAdminMenuHandler}
				>
					<use
						xlinkHref={
							process.env.REACT_APP_PUBLIC_URL + `/img/sprite.svg#bold-mini-arrow`
						}
					></use>
				</svg>

				{props.adminMenuView && <MobileAdminMenu />}
			</>
		</ArrowGoBack>
	);
};

export const Admin = connect(
	state => ({
		adminMenuView: state.ui.adminMenuView,
	}),
	dispatch => ({
		toggleAdminMenu: () => {
			dispatch(toggleAdminMenu());
		},
		closeAdminMenu: () => {
			dispatch(closeAdminMenu());
		},
	}),
	null,
	{ pure: false }
)(AdminMain);

export const Forbidden = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Доступ запрещен</span>
		</ArrowGoBack>
	);
};

export const University = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Корпоративный университет</span>
		</ArrowGoBack>
	);
};

export const Literature = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Литература</span>
		</ArrowGoBack>
	);
};

export const Competencies = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Корпоративные компетенции</span>
		</ArrowGoBack>
	);
};

export const Calendar = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Календарь</span>
		</ArrowGoBack>
	);
};

export const CalendarNY = props => {
	return (
		<ArrowGoBack {...props}>
			<span>С Новым Годом!</span>
		</ArrowGoBack>
	);
};

export const Education = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Заявка на обучение</span>
		</ArrowGoBack>
	);
};

export const Favorites = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Избранное</span>
		</ArrowGoBack>
	);
};

export const Gallery = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Галерея</span>
		</ArrowGoBack>
	);
};

export const Updates = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Обновления</span>
		</ArrowGoBack>
	);
};

export const Mentoring = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Менторинг</span>
		</ArrowGoBack>
	);
};

export const CorpValues = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Корпоративные ценности</span>
		</ArrowGoBack>
	);
};

export const Achievements = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Достижения</span>
		</ArrowGoBack>
	);
};

export const Quiz = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Новогодний квиз</span>
		</ArrowGoBack>
	);
};

export const LTR = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Обучение</span>
		</ArrowGoBack>
	);
};

export const VHI = props => {
	return (
		<ArrowGoBack {...props}>
			<span>ДМС</span>
		</ArrowGoBack>
	);
};

export const ChatBotList = props => {
	return (
		<ArrowGoBack {...props}>
			<span>Диалоги</span>
		</ArrowGoBack>
	);
};

export const ChatBot = props => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [name, setName] = useState(' ');

	useEffect(() => {
		(async () => {
			if (!id) return;

			const { response, responseCode } = await getChatbotForUserRest(id);

			if (!responseCode) {
				setName(response.name);
			}
		})();
	}, [dispatch, id]);

	return (
		<ArrowGoBack {...props}>
			<span>{name}</span>
		</ArrowGoBack>
	);
};
